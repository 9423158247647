import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/Home/logo.png";
import logo_mob from "../img/Home/logo.png";
import MobileMenu from "./MobileMenu";

function Header() {
    const { t } = useTranslation();
    const location = useLocation();
    
    const [isProductsOpen, setIsProductsOpen] = useState(false);

    const closeProductsMenu = () => {
        setIsProductsOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsProductsOpen(false);
        };
        
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const languages = [
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
        { value: 'tr', label: 'TR' },
        { value: 'pl', label: 'PL' },
        { value: 'de', label: 'DE' },
        { value: 'cs', label: 'CS' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        // eslint-disable-next-line
    }, [storedLang]);
    

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    return (
        <div className="bg-[#EAEAE5] z-[999] relative">
            <div className="hidden max-w-[1280px] h-[60px] justify-between mx-auto xl:flex items-center">
                <Link to="/"><img src={logo} alt="" className="w-[80px]"/></Link>
                <Link to="/products" onClick={closeProductsMenu}><p className={`text-[16px] font-light roboto ${location.pathname === '/products' ? 'text-[#FF5131]' : 'text-[#000000B2]'}`}>{t('header.1')}</p></Link>
                <Link to="/offers" onClick={closeProductsMenu}><p className={`text-[16px] font-light roboto ${location.pathname === '/offers' ? 'text-[#FF5131]' : 'text-[#000000B2]'}`}>{t('header.2')}</p></Link>
                <Link to="/accounts" onClick={closeProductsMenu}><p className={`text-[16px] font-light roboto ${location.pathname === '/accounts' ? 'text-[#FF5131]' : 'text-[#000000B2]'}`}>{t('header.3')}</p></Link>
                <Link to="/contactus" onClick={closeProductsMenu}><p className={`text-[16px] font-light roboto ${location.pathname === '/contactus' ? 'text-[#FF5131]' : 'text-[#000000B2]'}`}>{t('header.4')}</p></Link>
                <Link to="/aboutus" onClick={closeProductsMenu}><p className={`text-[16px] font-light roboto ${location.pathname === '/aboutus' ? 'text-[#FF5131]' : 'text-[#000000B2]'}`}>{t('header.5')}</p></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                />
                <a href="https://user.desc-acc.com/login">
                    <div className="button cursor-pointer">
                        <p className="inter text-white text-[16px]">{t('header.6')}</p>
                    </div>
                </a>
            </div>
            <div className="xl:hidden flex mx-[20px] items-center justify-between h-[60px]">
                <Link to="/"><img src={logo_mob} alt="" className="w-[80px]"/></Link>
                <Dropdown
                    options={languages}
                    placeholder="EN"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    className="ml-auto mr-[20px]"
                />
                <MobileMenu/>
            </div>
        </div>
    );
}

export default Header;
